import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';

const GET_BRAND = gql`
  query GetBrand($slug: String!) {
    brandBySlug(slug: $slug) {
      id
      title
    }
  }
`;

const Brand = () => {
  const params = useParams();
  const brandSlug = params['*'];
  const [getBrand, { data }] = useLazyQuery(GET_BRAND);

  useEffect(() => {
    if (brandSlug) {
      getBrand({ variables: { slug: brandSlug } });
    }
  }, [brandSlug]);

  if (data?.brandBySlug) {
    const brandTitle = data.brandBySlug.title;

    const queryParams = new URLSearchParams();
    queryParams.set('brand', brandTitle);

    return (
      <Navigate
        to={{
          pathname: '/tooted',
          search: `?${queryParams.toString()}`,
        }}
      />
    );
  }

  return null;
};

export default Brand;
